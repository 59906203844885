<template>
  <div class="topic-list-container">
    <div class="tlc__inner">
      <!-- 创建按钮 -->
      <router-link class="tlc__add-bt" tag="div" :to="{
        path: '/topic/edit'
      }">
        <div class="inner">
          <svg-icon icon-class="icon-plus"></svg-icon>
          <p>创建分享主题</p>
        </div>
      </router-link>
      <!-- 列表 -->
      <div class="topic-share-main-list-container">
        <topic-list @finished="onFinished" :header-nav="topicHeaderNav" src="api/user/topic/list"
          role="user"></topic-list>
      </div>
      <!-- slogan -->
      <empty style="margin-top: 300px;" v-if="isEmpty" hint="创建主题后会出现在这里" btn-text="去创建" link="/topic/edit"></empty>
      <slogan v-else text="没有人不是宝藏"></slogan>
      <!-- 添加按钮 -->
      <add-bt @click.native="onCreatePost" icon="icon-plus-square" color="#33658A"></add-bt>
    </div>
  </div>
</template>

<script>
import topicList from './_list.vue';
import slogan from '../common/_slogan.vue';
import addBt from '../common/_add-bt.vue';
import empty from '../common/_empty.vue';

export default {
  name: 'page-topic-list',
  components: {
    topicList,
    slogan,
    addBt,
    empty,
  },
  data() {
    return {
      topicHeaderNav: [
        {
          name: '排行',
        },
        {
          name: '分享主题',
        },
        {
          name: '权限',
        },
        {
          name: '分享',
        },
      ],
      isEmpty: false,
    };
  },
  methods: {
    onCreatePost() {
      this.generateAfterCreatePostLink(this.$route.fullPath);
      this.$router.push({
        path: '/topic/post/edit',
      });
    },
    onFinished(count) {
      this.isEmpty = count === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.tlc__add-bt {
  padding: 0 32px;

  .inner {
    height: 64px;
    background-color: #f4f4f4;
    border-radius: 10px;
    width: 100%;
    @include flex-box;
    justify-content: center;
    align-items: center;
  }
}
</style>
